@import "../../../../../styles/mixins.scss";
@import "../../../../../styles/variables.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.title {
  text-transform: uppercase;
  font-weight: 300;
  color: $text-primary-color;
  @include font-2xl;
}

.cabins {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cabin {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 20px;
  box-sizing: border-box;
  border: 2px solid $primary-color;
  @include transition(background-color, color);

  &_active {
    background-color: $primary-color;
    color: $text-secondary-color;

    .cellKey,
    .cellValue {
      color: $text-secondary-color;
    }
  }
}

.left {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.name {
  font-weight: 600;
  text-transform: uppercase;
  @include font-2xl;
}

.row {
  display: flex;
  flex-direction: column;
}

.cell {
  display: flex;
  align-items: center;
  gap: 10px;
}

.cellKey {
  font-weight: 600;
  color: $text-primary-color;
  white-space: nowrap;
  text-transform: uppercase;
  @include font-lg;
}

.cellValue {
  color: $subtext-color;
  white-space: nowrap;
  @include font-lg;
}

@include mobile-lg {
  .cabin {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .row {
    flex-direction: row;
    gap: 40px;
  }

  .button {
    width: 200px;
  }
}
