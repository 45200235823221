@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.container {
  text-transform: uppercase;
  padding: 20px 0 40px;
}

.container > nav {
  color: $input-text-color;
}

.text {
  margin-top: 20px !important;
}

@include tablet {
  .container {
    padding: 40px 0 60px;
  }
}
