@import "../../../../../styles/mixins.scss";
@import "../../../../../styles/variables.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.title {
  text-transform: uppercase;
  color: $text-primary-color;
  @include font-2xl;
}

.dataInfo {
  color: $text-primary-color;
}

.selectContainer {
  display: flex;
  flex-direction: column;
}

.select {
  border: 1px solid $secondary-color !important;
  color: $subtext-color !important;
}
