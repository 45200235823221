@import "../../../styles/mixins.scss";

.content {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;
}

.left,
.right {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 40px;
}

.right {
    gap: 20px;
}

.title {
    text-transform: uppercase;
    font-weight: 300;
    color: $text-primary-color;
    @include font-2xl;
}

.passengers {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.passenger {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.stateroomInfo {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.subtitle {
    color: $text-primary-color;
}

.subtitle,
.name {
    text-transform: uppercase;
    font-weight: 600;
    @include font-lg;
}

.name {
    color: $text-primary-color;
    @include font-base;

    span {
        color: $subtext-color;
        text-transform: none;
    }
}

@include tablet {
    .content {
        display: grid;
        gap: 40px;
        grid-template-columns: 3fr 1fr;
    }

    .title {
        @include font-3xl;
    }
}
