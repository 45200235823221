@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  gap: 20px;
}

.left,
.right {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 40px;
}

.right {
  gap: 20px;
}

.title {
  text-transform: uppercase;
  font-weight: 300;
  color: $text-primary-color;
  @include font-2xl;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0;
  padding: 20px;
  background-color: $element-background-primary;
}

.inputs {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.inputsRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.input {
  label,
  input {
    color: $input-text-color;

    &::placeholder {
      color: $input-placeholder-color;
    }
  }

  label {
    color: $text-primary-color;
    font-weight: 500;
  }
}

.input {
  input {
    border: 2px solid $secondary-color;
  }
}

.input[class^="CustomSelect_field"] {
  border: 2px solid $secondary-color;
}

@include mobile-lg {
  .form {
    gap: 20px;
  }
}

@include tablet {
  .content {
    display: grid;
    gap: 40px;
    grid-template-columns: 3fr 1fr;
  }

  .title {
    @include font-3xl;
  }

  .submitButton {
    width: unset;
    padding: 0 80px;
  }

  .inputs {
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    &Date {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }
  }
  .inputsRow {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}
