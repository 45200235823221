@import "../../../../../styles/variables.scss";

.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.link {
    color: $primary-color;
}
