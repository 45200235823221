@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.header {
    text-transform: uppercase;
    font-weight: 500;
    @include font-lg;
}

.body {
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: $text-primary-color;
}

.name {
    text-transform: uppercase;
    font-weight: 600;
    @include font-xl;
}

.tableHeading {
    text-transform: uppercase;
    font-weight: 600;
    @include font-lg;
}

.tableValue {
    text-align: right;
    color: $subtext-color;
}

.scheduleTableValue {
    text-align: left;
}

.line {
    margin: 0;
    background: $delimitter-line-color;
    height: 2px;
}

.room {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.roomNumber {
    text-transform: uppercase;
    @include font-2xl;
}

.roomName {
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 10px !important;
}

.guests {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.guest {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.guestHeading {
    font-weight: 500;
    @include font-base;
}

.totalPriceHeading,
.totalPriceValue {
    color: $primary-color;
}

.schedule {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
