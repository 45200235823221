// COLORS
$primary-color: var(--primary-color);
$secondary-color: var(--secondary-color);
$text-primary-color: var(--text-primary-color);
$text-secondary-color: var(--text-secondary-color);
$input-border-color: var(--input-border-color);
$input-background-color: var(--input-background-color);
$input-placeholder-color: var(--input-placeholder-color);
$input-text-color: var(--input-text-color);
$input-focus-border-color: var(--input-focus-border-color);
$error-color: var(--error-color);
$menu-background-color: var(--menu-background-color);
$menu-text-color: var(--menu-text-color);
$element-background-primary: var(--element-background-primary);
$element-background-secondary: var(--element-background-secondary);
$element-background-dark: var(--element-background-dark);
$background-color: var(--background-color);
$link-color: var(--link-color);
$section-background-color: var(--section-background-color);
$shadow-color: var(--shadow-color);
$subtext-color: var(--subtext-color);
$delimitter-line-color: var(--delimitter-line-color);
$svg-icons-primary: var(--svg-icons-primary);
$svg-icons-secondary: var(--svg-icons-secondary);
$search-button-background: var(--search-button-background);

// BREAK POINTS
$mobile-sm-width: 320px;
$mobile-base-width: 375px;
$mobile-md-width: 576px;
$mobile-lg-width: 768px;
$tablet-width: 992px;
$laptop-width: 1200px;
$desktop-width: 1920px;
