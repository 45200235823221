@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.container {
  max-width: 300px;
  padding: 6px 40px;
  box-sizing: border-box;
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%) scale(0.8);
  z-index: 3;
  opacity: 0;
  pointer-events: none;
  @include transition(transform, opacity);

  &_visible {
    transform: translateX(-50%) scale(1);
    opacity: 1;
  }

  &_success {
    background: $primary-color;
  }

  &_error {
    background: $error-color;
  }
}

.message {
  color: $text-secondary-color;
  white-space: nowrap;
  font-weight: 600;
}
